@import 'stylesheets/tools/index.scss';

.select {
  position: relative;

  input {
    cursor: pointer;
  }

  &__icon {
    position: absolute;
    top: rem(16);
    right: rem(16);
    width: rem(24);
    height: rem(24);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: var(--transition-timing);

    &--open {
      transform: rotate(180deg);
      transition: var(--transition-timing);
    }
  }

  &__options {
    border-radius: var(--radius-small);
    background-color: var(--color-white);
    position: absolute;
    top: rem(60);
    left: -1px;
    right: -1px;
    border: 1px solid var(--color-titan-blue);
    z-index: 1;
    padding: rem(8);
    overflow: auto;
    max-height: rem(170);
  }

  &__option {
    padding: rem(8);
    border-radius: rem(6);
    margin-bottom: rem(2);
    cursor: pointer;

    &:hover {
      background-color: var(--color-pale-blue);
    }
  }

  &__option--current {
    background-color: var(--color-pale-blue);
  }

  &__backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
}
