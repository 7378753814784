.flex {
  display: flex;

  &-wrap {
    flex-wrap: wrap;
  }
}

.align-items {
  &-center {
    align-items: center;
  }
}

.justify-content {
  &-center {
    justify-content: center;
  }
  &-space-between {
    justify-content: space-between;
  }
}

.gap {
  &-1 {
    gap: rem(8);
  }
  &-2 {
    gap: rem(16);
  }
  &-3 {
    gap: rem(24);
  }
}

.width {
  &-full {
    width: 100%;
  }
  &-50 {
    width: 50%;
  }
}
