@import 'stylesheets/tools/index.scss';

.create-password {
  h2 {
    margin-bottom: rem(16);
  }

  img {
    margin-bottom: rem(24);
  }
}
