@import 'stylesheets/tools/index.scss';

.password-input {
  input {
    padding-right: rem(48);
  }

  &__icon {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    right: rem(16);
    top: rem(16);

    svg {
      width: rem(22);
      height: rem(17);
    }
  }
}
