@import '../tools/index.scss';

h1 {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: 400;
  font-size: rem(54);
  line-height: rem(62);
}

h2 {
  font-family: var(--font-primary-bold);
  font-style: normal;
  font-weight: 400;
  font-size: rem(21);
  line-height: rem(32);
}

h3 {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: 400;
  font-size: rem(24);
  line-height: rem(29);
}

p {
  font-weight: var(--font-weight-normal);
  font-style: normal;
  font-size: rem(16);
  line-height: rem(24);
}

small {
  font-style: normal;
  font-weight: 400;
  font-size: rem(12);
  line-height: rem(20);
}

strong {
  font-family: var(--font-primary-bold);
  font-style: normal;
  font-weight: 400;
  font-size: rem(16);
  line-height: rem(24);
}

a {
  text-decoration: none;
  color: var(--color-midnight-blue);

  &:hover {
    color: var(--color-titan-blue);
  }
}
