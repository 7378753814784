@import 'stylesheets/tools/index.scss';

.title {
  padding-bottom: rem(16);
  text-align: center;

  h2 {
    margin-bottom: rem(16);
  }
}
