:root {
  /* ----------- COLORS ----------- */
  --color-midnight-blue: #09253c;
  --color-overlay-blue: rgba(var(--color-color-midnight-blue), 70%);
  --color-titan-blue: #337bbb;
  --color-pressed-blue: #20629c;
  --color-pale-blue: #f5f8fc;
  --color-blue-grey: #e6e9eb;
  --color-dark-grey: #c2c6c7;
  --color-light-grey: #e3e4e6;
  --color-dashboard-grey: #f2f2f2;
  --color-white: #ffffff;
  --color-green: #77b77f;
  --color-pale-green: #f1f8f2;
  --color-red: #e25b5b;
  --color-pale-red: #fbebeb;
  --color-orange: #ff7f50;
  --color-pale-orange: #fff2ed;

  // Box Shadow
  --box-shadow: 0px 3px 10px 0px rgba(9, 37, 60, 0.1);

  /* ----------- FONTS ----------- */
  --font-primary: 'Gotham Book', sans-serif;
  --font-primary-bold: 'Gotham Bold', sans-serif;
  --font-secondary: 'Bebas Neue', sans-serif;

  // Border radius
  --radius-xsmall: 2px;
  --radius-small: 4px;
  --radius-small-x2: 8px;
  --radius-medium: 16px;
  --radius-large: 70px;
  --radius-round: 100px;

  // Timing
  --transition-timing: 0.2s;

  /* ----------- Basic root properties ----------- */
  font-family: var(--font-primary);
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
