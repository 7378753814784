@import 'stylesheets/tools/index.scss';

.vehicleCardContainer {
  display: flex;
  flex-wrap: wrap;

  .vehicle {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    padding-top: 10px;
    box-sizing: border-box;
    margin-right: 20px;
    flex: 0 1 30%;

    &-card {
      background-color: var(--color-white);
      border-radius: var(--radius-small-x2);
      border: 1px solid var(--color-light-grey);
      max-width: rem(432);
      min-width: rem(422);
      overflow: hidden;
      padding: rem(16);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      position: relative;
    
      @media (max-width: 768px) {
        min-width: rem(220); // Adjust this value as needed for mobile screens
      }
    
      &__nickname {
        font-family: var(--font-primary-bold);
        margin-bottom: rem(8);
      }
    
      &__content {
        padding-bottom: rem(16);
      }
    
      &__map {
        padding-bottom: rem(16);
    
        .map {
          border-radius: var(--radius-small);
        }
      }
    
      &__services {
        position: absolute;
        margin-top: rem(-82);
        padding-left: rem(10);
      }
    
      &__actions {
        display: flex;
        align-items: center;
        gap: rem(16);
        max-width: 100%;
      }
    
      &__infoContainer {
        flex-direction: row;
        display: flex;
      }
    
      &__infoLabel {
        flex: 6;
        padding-top: 5px;
      }
    
      &__infoValue {
        flex: 4;
        padding-top: 5px;
      }
    }

    .coords {
      align-items: center;
      background-color: var(--color-dashboard-grey);
      border-radius: var(--radius-small);
      display: flex;
      margin-bottom: rem(16);
      padding: rem(16);

      small:not(:last-of-type) {
        margin-right: rem(8);
      }

      &__group {
        align-items: center;
        display: flex;
        flex: 1;
      }

      &__button {
        align-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        font-family: var(--font-primary-bold);
        font-size: rem(12);

        svg {
          margin-left: rem(8);
        }
      }
    }
  }

  .editable {
    cursor: pointer;
    color: #333;
    background-color: #f4f4f4;
    border-bottom: 1px dotted #ccc;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 2px;
    padding: 2px 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  
    &:hover {
      background-color: #e9e9e9;
      color: #000;
      border-bottom-color: #999;
    }
  
    &:active {
      background-color: #ddd;
      color: #000;
      border-bottom-color: #666;
    }
  }
  
}

.form-field-search {
  .results {
    background-color: var(--color-white);
    border-radius: var(--radius-small);
    border: 1px solid var(--color-titan-blue);
    left: 0;
    padding: rem(8);
    position: absolute;
    right: 0;
    top: rem(60);
    z-index: 1;
    height: rem(170);
    overflow-x: auto;

    .option {
      border-radius: var(--radius-small);
      margin-bottom: rem(2);
      padding: rem(10);
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: var(--color-pale-blue);
      }
    }
  }

  .results-loader {
    color: var(--color-dark-grey);
    text-align: center;
  }
}