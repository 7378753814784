@import 'stylesheets/tools/index.scss';

.password-checklist {
  margin: rem(24) 0 rem(32) 0;
  padding: 0;

  &__item {
    align-items: center;
    display: grid;
    gap: rem(8);
    grid-template-columns: auto 1fr;
    margin: rem(16) 0;

    &--valid {
      color: var(--color-green);
      transition: var(--transition-timing);

      & .password-checklist__icon {
        background-color: var(--color-green);
        border: 2px solid var(--color-green);
        color: var(--color-white);
      }
    }
  }

  &__icon {
    align-items: center;
    border-radius: 100%;
    border: 2px solid var(--color-dark-grey);
    color: var(--color-dark-grey);
    display: flex;
    height: rem(24);
    justify-content: center;
    padding: rem(4);
    transition: var(--transition-timing);
    width: rem(24);
  }

  &__label {
    text-align: left;
  }
}
