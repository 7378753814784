@import 'stylesheets/tools/index.scss';

.all-devices {
  .header {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: white;
    border-radius: 10px;
    padding: 8px 16px;
  }

  .button-grid {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .fab {
    position: fixed;
    border-radius: 10px;
    bottom: 20px;
    right: 50px;
    padding: 5px;
    width: 100px;
  }

  .test-summary {
    text-align: center;
    margin-top: 40px;

    .summary-container {
      flex: 1;
      margin: 20px;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 8px;
      text-align: center;

      .summary-row {
        display: flex;
        flex-direction: row;

        .summary-item {
          flex: 1;
          margin-bottom: 10px;

          h4 {
            margin: 0;
          }

          p {
            margin: 0;
          }

          .passed {
            color: green;
          }

          .failed {
            color: red;
          }
        }
      }

      .controls {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        width: 100%;
        text-align: center;
        justify-content: center;
        flex-wrap: wrap;

        .control-item {
          margin-top: 10px;
          margin-left: 10px;
          text-align: center;
          justify-content: center;
          flex-wrap: wrap;
        }

        .last-updated {
          margin-top: 15px;
          margin-left: 20px;
          text-align: center;
          justify-content: center;
          flex-wrap: wrap;
        }
      }
    }
  }

  .filter-navigation {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .modal-buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
  }

  .action-buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .button-separator {
      margin-left: 10px;
    }
  }

  .client-list__loader {
    text-align: center;
  }
}