@import 'stylesheets/tools/index.scss';

.button {
  background: var(--color-titan-blue);
  border-radius: var(--radius-small);
  border: 1px solid transparent;
  color: var(--color-white);
  cursor: pointer;
  font-size: rem(16);
  font-family: var(--font-primary-bold);
  line-height: rem(24);
  outline: none;
  padding: rem(16) rem(24);
  transition: var(--transition-timing);

  &:hover {
    background: var(--color-pressed-blue);
  }

  &:disabled {
    background-color: var(--color-dark-grey);
    pointer-events: none;
    transition: var(--transition-timing);
  }

  &:active {
    background-color: var(--color-pressed-blue);
  }

  &--full-width {
    width: 100%;
  }

  &--secondary {
    background: var(--color-pale-blue);
    border: 1px solid var(--color-pale-blue);
    color: var(--color-titan-blue);

    &:hover {
      background: var(--color-pale-blue);
      color: var(--color-pressed-blue);
    }

    &:disabled {
      pointer-events: none;
      color: var(--color-light-grey);
    }
  }

  &--outline {
    background: transparent;
    border: 1px solid var(--color-light-grey);
    color: var(--color-titan-blue);

    &:hover {
      background: var(--color-titan-blue);
      color: var(--color-white);
    }

    &:disabled {
      background: transparent;
      pointer-events: none;
      color: var(--color-light-grey);
    }
  }

  &--outline-filled {
    background: var(--color-titan-blue);
    border: 1px solid var(--color-light-grey);
    color: var(--color-white);

    &:hover {
      background: var(--color-titan-blue);
      color: var(--color-white);
    }

    &:disabled {
      background: transparent;
      pointer-events: none;
      color: var(--color-light-grey);
    }
  }

  &--small {
    padding: rem(8) rem(16);
  }

  &--xsmall {
    padding: rem(4) rem(8);
  }

  &--icon {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: rem(10);
    justify-content: center;
  }

  &--white {
    background: transparent;
    border: 1px solid var(--color-white);
    color: var(--color-white);

    &:hover {
      border: 1px solid var(--color-titan-blue);
      background: var(--color-titan-blue);
      color: var(--color-white);
    }
  }

  &--nowrap {
    white-space: nowrap;
  }

  &--dots {
    margin: rem(10);
  }
}
