@import 'stylesheets/tools/index.scss';

.toggle-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--reversed {
    flex-direction: row-reverse;
  }

  &__track-container {
    display: inline-block;
    height: rem(32);
    min-width: rem(72);
    position: relative;
  }

  &__slider {
    background-color: var(--color-dark-grey);
    border-radius: var(--radius-large);
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: var(--transition-timing);
    -webkit-transition: var(--transition-timing);

    &:before {
      background-color: var(--color-white);
      border-radius: var(--radius-large);
      bottom: rem(4);
      content: '';
      height: rem(24);
      left: rem(4);
      position: absolute;
      width: rem(24);
      transition: var(--transition-timing);
      -webkit-transition: var(--transition-timing);
    }

    &:after {
      color: var(--color-white);
      content: 'No';
      display: inline-block;
      position: relative;
      right: rem(5);
      top: rem(6);
      transform: translateX(rem(40));
      transition: var(--transition-timing);
      -ms-transform: translateX(rem(40));
      -webkit-transform: translateX(rem(40));
      -webkit-transition: var(--transition-timing);
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + &__slider {
    background-color: var(--color-titan-blue);
  }

  input:focus + &__slider {
    box-shadow: 0 0 1px var(--color-titan-blue);
  }

  input:checked + &__slider:before {
    -webkit-transform: translateX(rem(28));
    -ms-transform: translateX(rem(40));
    transform: translateX(rem(40));
  }

  input:checked + &__slider:after {
    content: 'Yes';
    display: inline-block;
    position: relative;
    right: rem(28);
    top: rem(6);
    -webkit-transform: translateX(rem(40));
    -ms-transform: translateX(rem(40));
    transform: translateX(rem(40));
  }
}
