@import 'stylesheets/tools/index.scss';

.company-card {
  background-color: var(--color-white);
  border-radius: var(--radius-small-x2);
  display: flex;
  flex-direction: column;
  margin-bottom: rem(8);

  &__header {
    align-items: center;
    display: flex;
    height: rem(60);
    padding: rem(8) rem(8) rem(8) rem(24);

    &-title {
      align-items: center;
      display: flex;
      flex: 1;
      font-family: var(--font-primary-bold);
      height: 100%;
      overflow: hidden;
      padding-right: rem(16);
      user-select: none;
    }

    &-name {
      display: block;
      overflow: hidden;
      padding-right: rem(16);
      text-overflow: ellipsis;
      white-space: nowrap;
      width: rem(280);
    }

    &-vehicle {
      align-items: center;
      display: flex;
      font-family: var(--font-primary);
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: rem(24);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      span {
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-of-type {
          display: inline-block;
          width: rem(130);
        }

        &:not(:last-of-type) {
          padding-right: rem(16);
        }
      }
    }

    &-menu {
      margin: rem(10);
    }

    .badge {
      margin-right: rem(8);
    }
  }

  &__body {
    display: none;

    &--expanded {
      display: flex;
      gap: rem(24);
      flex-wrap: wrap;
      padding: rem(8) rem(24) rem(24) rem(24);
    }
  }

  &__button-grid {
    & > :not(:last-child) {
      margin-right: rem(16);
    }
  }
}
