@import 'stylesheets/tools/index.scss';

.filter {
  align-items: center;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-light-grey);
  display: flex;
  font-family: var(--font-primary-bold);
  justify-content: space-between;
  padding: rem(16) rem(24) rem(16) rem(40);
  position: sticky;
  top: 0;
  z-index: 1;

  &-nav {
    a {
      margin-right: rem(8);
      opacity: 0.5;
      padding: rem(8);

      &:hover {
        color: var(--color-titan-blue);
        opacity: 1;
      }
      &:active {
        color: var(--color-pressed-blue);
      }
    }
  }

  a.filter-nav-active {
    opacity: 1;
  }
}

.dropdown-menu {
  margin-right: rem(10);
}
