@import 'stylesheets/tools/index.scss';

.device-card {
  background-color: var(--color-white);
  border-radius: var(--radius-small-x2);
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  &__header {
    align-items: center;
    display: flex;
    height: 60px;
    padding: 8px 8px 8px 24px;
    cursor: pointer;
    justify-content: space-between;

    &-title {
      align-items: center;
      display: flex;
      flex: 1;
      font-family: var(--font-primary-bold);
      height: 100%;
      overflow: hidden;
      padding-right: 16px;
      user-select: none;
    }

    &-serial {
      display: block;
      overflow: hidden;
      padding-right: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 280px;
      font-size: 20px;
    }

    &-status {
      align-items: center;
      display: flex;
      font-family: var(--font-primary);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      &-item {
        border: 1px solid #6c757d;
        border-radius: 4px;
        padding: 4px 8px;
        margin-right: 8px;
        display: inline-block;
  
        &:first-of-type {
          width: auto;
        }
  
        &:not(:last-of-type) {
          padding-right: 8px;
        }
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-of-type {
          display: inline-block;
          width: 150px;
        }

        &:not(:last-of-type) {
          padding-right: 16px;
        }
      }
    }

    &-menu {
      margin: 10px;
    }

    .badge {
      margin-right: 8px;
    }
  }

  &__body {
    display: none;
    padding: 8px 24px 24px 24px;

    &--expanded {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    h4 {
      font-family: var(--font-primary-bold);
      font-size: 18px;
      margin-bottom: 8px;
    }
  }

  &__button-grid {
    display: flex;
    align-items: center;
    margin-left: auto;

    & > :not(:last-child) {
      margin-right: 16px;
    }
  }

  &__test-results {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
    margin-top: 16px;

    &-card {
      border: 1px solid #6c757d;
      border-radius: var(--radius-small);
      padding: 16px;
      background-color: var(--color-white);
      max-height: 400px;
      overflow-x: auto;
      overflow-y: auto;

      h5 {
        font-family: var(--font-primary-bold);
        font-size: 16px;
        margin-bottom: 8px;
      }

      p {
        font-family: var(--font-primary);
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px;

        strong {
          font-family: var(--font-primary-bold);
        }
      }

      .device-card__test-data-item {
        margin-bottom: 8px;
      }

      .device-card__test-data-nested {
        margin-left: 16px;
      }

      .device-card__test-data-value {
        font-family: var(--font-monospace);
        font-size: 0.875rem;
        // color: var(--color-dark-grey);
        background-color: var(--color-light-blue); // Updated to very light blue
        padding: 0.5rem;
        border-radius: var(--radius-small);
      }
    }
  }

  &__status {
    font-weight: bold;
    
    &--passed {
      color: #28a745; // Green color for success
    }

    &--failed {
      color: #dc3545; // Red color for error
    }

    &--inprogress {
      color: #ffc107; // Yellow color for warning/in progress
    }

    &--notstarted {
      color: #6c757d; // Grey color for not started
    }
  }

  @media (max-width: 768px) {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      padding: 16px;

      &-title {
        width: 100%;
        padding-right: 0;
      }

      &-serial {
        width: 100%;
        padding-right: 0;
        font-size: 18px;
      }

      &-status {
        display: none;
      }
    }

    &__button-grid {
      width: 100%;
      justify-content: flex-end;
      margin-top: 8px;
    }

    &__body {
      padding: 16px;

      &--expanded {
        .device-card__header-status {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 16px;
        }
      }
    }
  }
}