@import 'stylesheets/tools/index.scss';

.modal {
  &__title {
    font-family: var(--font-primary-bold) !important;
    padding: rem(24) !important;
  }

  &__icon {
    border-radius: var(--radius-round);
    background: var(--color-light-grey);
    cursor: pointer;
    height: rem(32);
    width: rem(32);
  }

  &__content {
    font-family: var(--font-primary);
    line-height: rem(24);
    padding: 0 rem(16);

    .info-card__icon {
      display: none;
    }
  }

  .MuiPaper-root {
    border-radius: var(--radius-medium);
    min-width: rem(300);
    width: 100vw; // Set the width to 100% of the viewport width
    max-width: rem(800);
    // margin: 10; // Remove default margin
  }
}