@import 'stylesheets/tools/index.scss';

.map-view {
  height: rem(350);
  max-width: rem(400);

  &__commands {
    position: absolute;
    bottom: 0;
    left: rem(10);

    button {
      margin-bottom: rem(10);
      &:not(:last-child) {
        margin-right: rem(10);
      }
    }
  }

  &__notification {
    background-color: transparent;
    border-radius: var(--radius-small);
    color: var(--color-white);
    padding: rem(4) rem(8);
    text-align: left;
    position: absolute;
    bottom: rem(60);
    left: rem(10);

    &--error {
      background-color: var(--color-red);
    }

    &--success {
      background-color: var(--color-green);
    }
  }
}

.map {
  height: 100%;
  width: 100%;
}

// Google maps overrides
.gm-style-cc {
  display: none;
}

img[alt='Google'] {
  display: none;
}

.gm-ui-hover-effect {
  display: none !important;
}

.info-window {
  min-width: rem(220);
  padding-top: rem(16);
  color: var(--color-midnight-blue);

  &__close {
    cursor: pointer;
    position: absolute;
    right: rem(12);
    top: rem(12);
  }

  button {
    margin-top: rem(16);
  }
}
