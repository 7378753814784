@font-face {
  font-family: 'Gotham Book';
  src: url('../../assets/fonts/gothambook-webfont.woff2') format('woff2'),
       url('../../assets/fonts/gothambook-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham bold';
  src: url('../../assets/fonts/gothambold-webfont.woff2') format('woff2'),
       url('../../assets/fonts/gothambold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('../../assets/fonts/bebasneue-regular-webfont.woff2') format('woff2'),
       url('../../assets/fonts/bebasneue-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
