@import 'stylesheets/tools/index.scss';

.checkbox {
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  input:checked ~ &__indicator {
    background-color: var(--color-titan-blue);
    border: 1px solid var(--color-titan-blue);

    svg {
      height: rem(12);
      opacity: 1;
      transition: var(--transition-timing);
      visibility: visible;
      width: rem(14);
    }
  }

  input:focus ~ &__indicator,
  input:hover ~ &__indicator {
    border: 2px solid var(--color-titan-blue);
  }

  &__indicator {
    align-items: center;
    border-radius: var(--radius-small);
    border: 2px solid var(--color-light-grey);
    display: flex;
    flex-shrink: 0;
    height: rem(24);
    justify-content: center;
    margin-right: rem(16);
    width: rem(24);

    svg {
      height: 0px;
      opacity: 0;
      transition: var(--transition-timing);
      visibility: hidden;
      width: 0px;
      color: var(--color-white);
    }
  }

  label {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    line-height: rem(24);
  }
}
