@import 'stylesheets/tools/index.scss';

.radio-button {
  align-items: center;
  border-radius: var(--radius-small);
  border: 1px solid var(--color-light-grey);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: rem(32) rem(16);
  text-align: center;

  input {
    accent-color: var(--color-titan-blue);
    height: rem(24);
    margin-bottom: rem(16);
    width: rem(24);
  }

  &--active {
    border: 1px solid var(--color-titan-blue);
    background: var(--color-pale-blue);
  }
}
