@import 'stylesheets/tools/index.scss';

.company-list {
  padding: rem(24);

  &__loader {
    text-align: center;
    padding-top: rem(24);
  }
}

.account {
  &__loader {
    text-align: center;
    padding: rem(56);
  }
}
