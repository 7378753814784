@import 'stylesheets/tools/index.scss';

.fixed-layout {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  &--dark {
    background-color: var(--color-midnight-blue);
    color: var(--color-white);
  }

  &--text-center {
    text-align: center;
  }

  &__header {
    background-color: inherit;
    border-bottom: 1px solid var(--color-light-grey);
    font-family: var(--font-primary-bold);
    padding: rem(24) 0;
    text-align: center;
    position: relative;
  }

  &__main {
    flex: 1;
    overflow: auto;
    padding: rem(32) 0;
  }

  &__footer {
    background-color: inherit;
    border-top: 1px solid var(--color-light-grey);
    padding: rem(24) 0;
  }
}

.form-header-close {
  align-items: center;
  background-color: var(--color-light-grey);
  border-radius: 100%;
  display: flex;
  height: rem(32);
  justify-content: center;
  padding: rem(8);
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: rem(32);
}

.back-arrow {
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(16);
  background: var(--color-light-grey);
}
