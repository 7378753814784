@import 'stylesheets/tools/index.scss';

.badge {
  align-items: center;
  border-radius: var(--radius-round);
  display: inline-flex;
  font-family: var(--font-primary-bold);
  font-size: rem(12);
  font-style: normal;
  gap: rem(4);
  line-height: rem(20);
  padding: rem(4) rem(12);

  &--default,
  &--unlocked,
  &--no-device {
    background-color: var(--color-blue-grey);
    color: var(--color-midnight-blue);
  }

  &--low-car-battery,
  &--low-device-battery {
    background-color: var(--color-pale-orange);
    color: var(--color-orange);
  }

  &--alert {
    background-color: var(--color-pale-red);
    color: var(--color-red);
  }

  &--locked {
    background-color: var(--color-pale-green);
    color: var(--color-green);
  }
}
