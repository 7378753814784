@import 'stylesheets/tools/index.scss';

.menu {
  &-button {
    background: none;
    border: none;
    cursor: pointer;
  }
}

.MuiPaper-root {
  background: var(--color-white);
  border-radius: var(--radius-small-x2);
  box-shadow: var(--box-shadow);

  ul {
    display: flex;
    flex-direction: column;
    min-width: rem(312);
    max-width: rem(312);
    padding: rem(8);

    .menu-link {
      &:hover {
        border-radius: var(--radius-xsmall);
        color: var(--color-midnight-blue);
      }
      &-active {
        align-items: center;
        background: var(--color-pale-blue);
        border-radius: var(--radius-xsmall);
        display: flex;
        width: 100%;
      }
    }

    li {
      font-family: var(--font-primary);
      height: rem(40);
      padding: rem(0);
      padding-left: rem(8);
      transition: background-color var(--transition-timing) ease;
      -webkit-transition: background-color var(--transition-timing) ease;
    }
  }
}
