@import 'stylesheets/tools/index.scss';

.not-found {
  align-items: center;
  background-color: var(--color-midnight-blue);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  h1 {
    color: var(--color-white);
  }

  p {
    color: var(--color-white);
    margin-bottom: rem(24);
  }
}
