@import 'stylesheets/tools/index.scss';

.success {
  h2,
  p {
    margin-bottom: rem(16);
  }

  img {
    margin-bottom: rem(56);
  }

  .fixed-layout__main {
    padding-top: 0;
  }

  .download-links {
    margin-top: rem(56);
    display: flex;
    gap: rem(8);

    a {
      height: rem(60);
      max-width: 100%;
      display: inline-block;
    }

    svg {
      height: 100%;
      width: 100%;
    }
  }
}
