@import 'stylesheets/tools/index.scss';

.login {
  h2 {
    margin-bottom: rem(16);
  }

  img {
    margin-bottom: rem(24);
  }

  &__links {
    text-align: left;
    a {
      font-family: var(--font-primary-bold);
      font-size: rem(16);
    }
  }
}
