@import 'stylesheets/tools/index.scss';

.dashboard {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  main {
    background-color: var(--color-dashboard-grey);
    height: 100%;
    overflow-x: scroll;
    padding-bottom: rem(100);
  }
}

.header {
  align-items: center;
  background: var(--color-midnight-blue);
  border-bottom: 1px solid var(--color-dashboard-grey);
  display: flex;
  justify-content: space-between;

  &__left {
    align-items: center;
    display: flex;
  }

  &__logo {
    a {
      background-color: var(--color-titan-blue);
      display: inline-block;
      padding: rem(10) rem(32);
    }
  }

  &__navigation {
    padding: 0px rem(10);

    a {
      border-radius: var(--radius-small-x2);
      color: var(--color-white);
      display: inline-block;
      font-family: var(--font-primary-bold);
      font-size: rem(16);
      font-style: normal;
      line-height: rem(24);
      padding: rem(10) rem(24);
    }
  }

  &__menus {
    align-items: center;
    display: flex;
    gap: rem(16);
    padding-right: rem(24);
  }
}

.navigation--active {
  background: rgba(255, 255, 255, 0.1);
}
