@import 'stylesheets/tools/index.scss';

.badge-card {
  background: var(--white);
  border-radius: var(--radius-small);
  border: 1px solid var(--color-light-grey);
  margin-bottom: rem(24);

  &__fields {
    padding: rem(16) rem(24);
  }

  &__title {
    align-items: center;
    display: flex;
    font-family: var(--font-primary-bold);
    justify-content: space-between;
    line-height: rem(24);
    margin-bottom: rem(16);
  }

  &__date-time {
    background-color: var(--color-light-grey);
    font-size: rem(12);
    padding: rem(16) rem(24);
  }

  &__detail {
    align-items: baseline;
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: rem(12);

    span {
      padding-left: rem(10);
      text-align: right;
    }

    strong {
      font-size: rem(12);
    }
  }
}
