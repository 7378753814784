@import 'stylesheets/tools/index.scss';

.form {
  &__edit {
    .tel-prefix {
      top: rem(16);
    }
  }
  &-actions {
    border-top: 1px solid var(--color-light-grey);
    display: flex;
    justify-content: flex-end;
    padding: rem(24) rem(24) rem(8) rem(24);
    margin: 0 rem(-24);
  }
}
