@import '../tools/index.scss';

.narrow-container {
  margin: 0 auto;
  max-width: rem(480);
  min-height: 100%;
  padding: 0 rem(24);

  &--center-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--center-text {
    text-align: center;
  }

  &-align--space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-align--end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.not-narrow-container {
  margin: 0 auto;
  max-width: 80%;
  min-height: 100%;
  padding: 0 rem(24);

  &--center-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--center-text {
    text-align: center;
  }

  &-align--space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-align--end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
