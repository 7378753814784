@import 'stylesheets/tools/index.scss';

@mixin label-transformed-style {
  background-color: var(--color-white);
  color: var(--color-midnight-blue);
  font-family: var(--font-primary-bold);
  font-size: rem(12);
  font-weight: 400;
  line-height: rem(20);
  padding: 0 rem(2);
  top: rem(-11);
  transition: var(--transition-timing);
}

.form-field {
  position: relative;

  &__message {
    display: block;
    padding-top: rem(8);
    text-align: left;

    &:empty {
      display: none;
    }

    &--error {
      color: var(--color-red);
    }
  }

  input {
    background: var(--color-white);
    border-radius: var(--radius-small);
    border: 1px solid var(--color-dark-grey);
    color: var(--color-midnight-blue);
    font-size: rem(16);
    height: rem(56);
    line-height: rem(24);
    outline: none;
    padding: rem(16);
    width: 100%;

    &.field-error {
      border: 1px solid var(--color-red);
    }

    &::placeholder {
      color: transparent;
    }

    &:focus {
      border: 1px solid var(--color-titan-blue);
      & + label {
        @include label-transformed-style;
      }
    }

    &:not(:placeholder-shown) {
      & + label {
        @include label-transformed-style;
      }
    }

    &:disabled {
      color: var(--color-overlay-blue);
      opacity: 70%;
    }
  }

  label {
    position: absolute;
    left: rem(16);
    top: rem(16);
    transition: var(--transition-timing);
    font-size: rem(16);
    line-height: rem(24);
    color: var(--color-dark-grey);
  }
}

.tel-prefix {
  position: absolute;
  top: rem(19);
  left: rem(16);
}

input.tel-input {
  padding-left: rem(50);
}

label.tel-input {
  padding-left: rem(40);
}

.optional-text {
  color: var(--color-dark-grey);
  font-family: var(--font-primary-bold);
  font-size: rem(12);
  font-style: normal;
  line-height: rem(20);
  position: absolute;
  right: rem(16);
  top: 50%;
  transform: translate(0, -50%);
}

.form-field-search {
  .results {
    background-color: var(--color-white);
    border-radius: var(--radius-small);
    border: 1px solid var(--color-titan-blue);
    left: 0;
    padding: rem(8);
    position: absolute;
    right: 0;
    top: rem(60);
    z-index: 1;
    height: rem(170);
    overflow-x: auto;

    option {
      border-radius: var(--radius-small);
      margin-bottom: rem(2);
      padding: rem(10);
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: var(--color-pale-blue);
      }
    }
  }

  .results-loader {
    color: var(--color-dark-grey);
    text-align: center;
  }
}
