@import 'stylesheets/tools/index.scss';

.info-box {
  background: var(--color-pale-blue);
  border-radius: var(--radius-small);
  padding: rem(16);
  text-align: center;

  &__icon {
    margin-bottom: rem(8);

    img {
      height: rem(20);
      width: rem(20);
    }
  }

  &__title {
    margin-bottom: rem(8);
    font-family: var(--font-primary-bold);
  }

  &__helperText {
    display: block;
  }
}
