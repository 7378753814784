@import 'stylesheets/tools/index.scss';

.verify {
  h2,
  p {
    margin-bottom: rem(16);
  }
}

.otp-group {
  display: flex;
  justify-content: center;
}

.otp-input {
  border-radius: var(--radius-small);
  border: 1px solid var(--color-dark-grey);
  height: rem(64);
  outline: none;
  text-align: center;
  width: 20%;
  max-width: rem(64);

  &:not(:last-child) {
    margin-right: rem(8);
  }

  &:focus,
  &:hover {
    border: 1px solid var(--color-titan-blue);
  }
}

.link-disabled {
  pointer-events: none;
  opacity: 0.5;
}
