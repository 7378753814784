@import 'stylesheets/tools/index.scss';

.info-card {
  background: var(--color-pale-blue);
  border-radius: var(--radius-small);
  padding: rem(24);
  margin-bottom: rem(16);

  &__title {
    font-family: var(--font-primary-bold);
    margin-bottom: rem(16);
    margin-top: rem(16);
  }

  &__subtitle {
    font-family: var(--font-primary-bold);
    margin-bottom: rem(8);
  }

  &__fields {
    display: flex;
    flex-wrap: wrap;
    gap: rem(24);
  }

  &__field {
    overflow-wrap: anywhere;
    width: 46%;

    &-full {
      width: 100%;
    }
  }

  &__field-title {
    font-family: var(--font-primary-bold);
    font-size: rem(12);
    margin-top: rem(8);
  }

  &__icon {
    align-items: center;
    background-color: var(--color-light-grey);
    border-radius: 100%;
    display: flex;
    height: rem(32);
    justify-content: center;
    padding: rem(8);
    width: rem(32);
  }

  &__button-wrapper {
    display: flex;
    gap: rem(8);
  }
}
